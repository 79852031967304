import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { FormsModule  } from '@angular/forms';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import {  TestComponent } from './test/test.component';
import { AppComponent, ApplicationService, AuthGuard, AuthService, getSaver, MaterialModule, PaginatedService, RcCommonsModule, SAVER, SidenavService, StyleManagerService, ThemeService, TokenInterceptorService, UserService } from '@devteam/rc-commons';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({

  declarations: [
    TestComponent
  ],
  imports: [
   
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule, 
    FormsModule,
    RcCommonsModule, 
    MatDialogModule,
    FlexLayoutModule,
    TranslateModule.forRoot()
  ], 

  providers: [AuthService, AuthGuard, DatePipe, ApplicationService, UserService, ThemeService,
    SidenavService,
    StyleManagerService, PaginatedService,
 
    {
      provide: APP_INITIALIZER,
      useFactory: (applicationService: ApplicationService) => () => { return applicationService.load() },
      deps: [ApplicationService],
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    } ,
    {provide: SAVER, useFactory: getSaver}


  ],
  bootstrap: [AppComponent]
})



export class AppModule {

}
