import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationService, DownloadListComponent, FilemanagerComponent } from '@devteam/rc-commons';

 

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit,AfterViewInit {
  public userid : string;
  public date: Date;
  public contents: string  ;
  public contents2: string  ;
  @ViewChild('filemanager1') filemanager: FilemanagerComponent;
  @ViewChild('resourcefile1') resourcefile: DownloadListComponent;
  public config    = {
    
    toolbar: {
      items: [
        'bold',
        'italic',
        'underline',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'undo',
        'redo',
      ]
    },
    image: {
      toolbar: [
        'imageStyle:full',
        'imageStyle:side',
        '|',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    },
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: 'en'
  };  
  constructor(private applicationService: ApplicationService) { 
    
    this.date = new Date();

  }
  ngAfterViewInit(): void {
 
  }
  public myCallbackFunction  (startdate: Date, enddate: Date)  {//callback code here
    console.log(startdate);
    console.log(enddate);
  }
  public uploadedFuction (obj:any)  {//callback code here
    this.resourcefile.refresh();
 
  }
  public testCompleted(data){
   console.log('testcomplted:'+data);
  }
  public testDeleted(data){
    console.log('testDeleted:'+data);
    this.resourcefile.refresh();
  }

  ngOnInit(): void {
    
    this.applicationService.getCurrentUser().subscribe(data => {
      this.userid = data.id;
    });
 
  }

  public executeSelectedChange = (event) => {
    console.log(event);
  }
}
