import { NgModule } from '@angular/core';
import { Routes, RouterModule, RoutesRecognized, Route } from '@angular/router';
import { commonsRoutes } from '@devteam/rc-commons';
import { TranslateModule } from '@ngx-translate/core';
 
import { TestComponent } from './test/test.component';
 
 
 

 
export const routes: Routes = [...commonsRoutes,

  { path: 'test', component: TestComponent }
];
 
 
@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
 